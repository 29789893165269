var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-apps-search"},[_c('div',{staticClass:"page-apps-search-input",class:{
      'page-apps-search-input--focused': _vm.searchFocused,
      'page-apps-search-input--expanded': _vm.searchSuggestionsVisible,
    }},[_c('icon-search',{staticClass:"page-apps-search-input__icon"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInputValue),expression:"searchInputValue"}],staticClass:"page-apps-search-input__input",attrs:{"type":"search","placeholder":_vm.$t('apps.search')},domProps:{"value":(_vm.searchInputValue)},on:{"focus":_vm.focus,"blur":_vm.blur,"input":[function($event){if($event.target.composing)return;_vm.searchInputValue=$event.target.value},_vm.searchInputChange]}}),_vm._v(" "),_c('div',{staticClass:"page-apps-search-input__right"},[(_vm.loading)?_c('icon-loading'):((_vm.searchInputValue || '').length > 0)?_c('icon-close',{nativeOn:{"click":function($event){return _vm.clearSearch.apply(null, arguments)}}}):_vm._e()],1)],1),_vm._v(" "),(_vm.showSuggestions)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchSuggestionsVisible),expression:"searchSuggestionsVisible"}],staticClass:"page-apps-search-suggestions"},[_vm._l((_vm.searchSuggestions),function(app){return _c('ui-link',{key:app.slug,staticClass:"page-apps-search-suggestions__suggestion",attrs:{"to":{
          name: 'app',
          params: { app: app.slug },
        }}},[_c('search-suggestion',_vm._b({},'search-suggestion',app,false))],1)}),_vm._v(" "),(_vm.searchEmpty)?_c('div',{staticClass:"page-apps-search-suggestions__box-empty"},[_c('span',[_vm._v("Ничего не найдено")])]):_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }